import { createRouter, createWebHistory } from "vue-router";

import { auth, functions } from "@/core/firebase";

import { httpsCallable } from "firebase/functions";

const authorizeStripeStandardAccount = httpsCallable(functions, "authorizeStripeStandardAccount");

const routes = [
  {
    path: "/",
    name: "home",
    async beforeEnter() {
      const landingPage = process.env.VUE_APP_LANDING_PAGE_URL;
      window.location.replace(landingPage);
    },
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/:name",
        name: "restourant",
        component: () => import("@/views/Menu.vue"),
      },
      {
        path: "/bill/:id",
        name: "bill",
        component: () => import("@/views/UserBill.vue"),
      },
      {
        path: "/checkout/:transactionID/:paymentIntentID/:secret",
        name: "checkout",
        component: () => import("@/views/CheckOut.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Profile.vue"),
      },
      {
        path: "/profile/orders",
        name: "orders",
        component: () => import("@/views/profile/OrderList.vue"),
      },
      {
        path: "/profile/billing",
        name: "billing",
        component: () => import("@/views/profile/BillingSetting.vue"),
      },
    ],
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/Terms.vue"),
  },
  {
    path: "/authorize",
    name: "authorize",
    async beforeEnter(to) {
      const stripeID = to.query.stripeID;

      await authorizeStripeStandardAccount({
        stripeID: stripeID,
      }).then((response) => {
        window.location.replace(response.data.url);
      });
    },
    component: () => import("@/components/content/Loader.vue"),
  },
  {
    path: "/success",
    name: "success",
    component: () => import("@/components/content/Success.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const needLogin = ["profile", "orders", "billing"];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  needLogin.forEach((pathName) => {
    if (to.name == pathName && !auth.currentUser) {
      window.location.replace("/404");
    }
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
