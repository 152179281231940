import { loadStripe } from "@stripe/stripe-js";

const stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
const stripe = await loadStripe(stripePublicKey);

const appearance = {
  style: {
    base: {
      border: "1px solid black",
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "red",
    },
  },
};

const confirmCardPayment = async (
  stripeConnectAccountID,
  clientSecret,
  paymentMethod
) => {
  const stripeConnectAccount = await loadStripe(stripePublicKey, {
    stripeAccount: stripeConnectAccountID,
  });
  console.log(paymentMethod)

  return await stripeConnectAccount.confirmCardPayment(clientSecret, {
    payment_method: paymentMethod,
  });
};

export { stripe, appearance, confirmCardPayment };
