import { createApp } from "vue";
import App from "./App.vue";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/core/firebase";

import "@/core/stripe"

let app;

// Plugins

import router from "./router";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

// Style

import "bootstrap";
import "@/assets/scss/style.scss";

// Create App

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App).use(router).use(pinia).use(router).mount("#app");
  }
});
