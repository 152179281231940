// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAz3V51DnMJ7I1SUhUS-TsLTgZF-M1svKI",
  authDomain: "interactivemenu-96122.firebaseapp.com",
  projectId: "interactivemenu-96122",
  storageBucket: "interactivemenu-96122.appspot.com",
  messagingSenderId: "389584524208",
  appId: "1:389584524208:web:b4d8ccf499bf6586013c75",
  measurementId: "G-42NDGCVQL8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);

functions.region = "europe-west1";

if (location.hostname === "localhost") {
  console.log("emulator");
  connectFirestoreEmulator(db, "127.0.0.1", 8081);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFunctionsEmulator(functions, "127.0.0.1", 5001)
}

export { db, auth, functions };
